/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import background from "../images/top_banner.png"
import stripes from "../images/stripes_two.png"
import { FaFacebookF, FaTwitter, FaYoutube, FaInstagram } from "react-icons/fa"

import Header from "./header"
import "./layout.css"
import "typeface-oswald"
import "typeface-roboto"

const Footer = styled.footer`
  text-align: center;
  font-family: sans-serif;
  font-size: 0.8rem;
  margin-top: 5rem;
  padding-bottom: 2rem;
  a {
    color: white;
    margin: 1rem;
    font-size: 2rem;
  }
  text-decoration: none;
`

const Stripes = styled.div`
  background-image: url(${stripes});
  background-repeat: no-repeat;
  margin: 0 auto;
  background-position-x: 35%;
  background-size: 500px;
  @media (max-width: 768px) {
    background-position-x: 90%;
    background-size: 200px;
  }
`
const Wrapper = styled.div`
  color: white;
  @media (max-width: 768px) {
    padding-top: 5rem;
  }
`

console.log(" Dev work by Carmen Cincotti. Find me on GitHub! ccincotti3")

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div>
        <div
          style={{
            backgroundImage: `url(${background}), linear-gradient(#11202a, #0a1721)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        >
          <Stripes>
            <Header id="desktop-nav" siteTitle={data.site.siteMetadata.title} />
            <Wrapper
              css={{
                paddingTop: 0,
                minHeight: "100vh",
                margin: "0 auto",
              }}
            >
              <main>{children}</main>
              <Footer>
                <div>
                  <a href="https://www.facebook.com/eatfast/" target="_blank">
                    <FaFacebookF />
                  </a>
                  <a href="https://twitter.com/eatingcontest" target="_blank">
                    <FaTwitter />
                  </a>
                  <a
                    href="https://www.instagram.com/eatingcontest/"
                    target="_blank"
                  >
                    <FaInstagram />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UClP_eBypwxmT3_3Qc5lN6Kg"
                    target="_blank"
                  >
                    <FaYoutube />
                  </a>
                </div>
                <div>
                  <span>
                    © {new Date().getFullYear()}, Major League Eating &#xb7;
                    International Federation of Competitive Eating, Inc.
                  </span>
                </div>
                <div>
                  560 Fifth Avenue, 4th Floor &#xb7; New York, NY 10036
                </div>
              </Footer>
            </Wrapper>
          </Stripes>
        </div>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

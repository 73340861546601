import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "@emotion/styled"
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import FluidContainer from "./fluidContainer"
import MobileNav from "./mobileNav"

const Banner = styled.div`
  background-color: #003357;
  padding: 1rem 0;
  border-bottom: 1px solid white;
  font-family: oswald;
  color: white;
  text-align: right;
  @media (max-width: 768px) {
    display: none;
  }
  a {
    font-family: oswald;
    display: inline-block;
    margin-left: 1rem;
    color: #003357;
    border: none;
    cursor: pointer;
    background-color: white;
    padding: 0.5rem 2rem;
    &:hover {
      filter: brightness(0.8);
    }
  }
`

const HeaderContainer = styled.header``

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 100;
  @media (max-width: 768px) {
    display: none;
  }
`
const PrimaryNavLinks = styled.ul`
    display: flex;
    transform: skew(-25deg);
    margin: 0;
    list-style: none;
    li {
      white-space: nowrap;
      font-family: oswald;
      text-transform: uppercase;
      position: relative;
      margin-bottom: 0;
      a {
        background-color: #003357;
        padding: 0.5rem 1.2rem;
        margin-left: -2px;
        &:hover {
          filter: brightness(1.5);
        }
        span {
          display: inline-block;
          transform: skew(25deg);
          & > * {
            transform: skew(0deg);
          }
        }
        @media (max-width: 992px) {
          padding: 0.5rem;
          .eating {
            display: none;
          }
        }
      }
      .lastLink {
        background-color: #b5232b;
        padding: 0.5rem 1rem;
        &:hover {
          filter: brightness(1);
        }
      }
  `
const SecondaryNav = styled.ul`
  position: absolute;
  bottom: -10.2rem;
  font-size: 0.9rem;
  z-index: 10;
  left: 2.3rem;
  font-weight: 300;
  margin: 0;
  list-style: none;
  transform: skew(25deg);
  a {
    display: block;
  }
`

const Header = ({ siteTitle }) => {
  const [showContestNav, setShowContestNav] = useState(false)

  return (
    <HeaderContainer>
      <Banner>
        <FluidContainer>
          Major League Eating is a proud supporter of Feeding America
          <a href="https://www.feedingamerica.org/">Donate Now</a>
        </FluidContainer>
      </Banner>
      <MobileNav id="mobile-nav" />
      <FluidContainer style={{ paddingBottom: 0 }}>
        <Nav>
          <div
            css={{
              width: "230px",
              margin: "1rem 2rem 1rem 0",
              position: "relative",
              top: "-10px",
            }}
          >
            <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              <StaticQuery
                query={graphql`
                  query {
                    placeholderImage: file(relativePath: { eq: "logo.png" }) {
                      childImageSharp {
                        fluid(maxWidth: 300) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                `}
                render={data => (
                  <Img fluid={data.placeholderImage.childImageSharp.fluid} />
                )}
              />
            </Link>
          </div>
          <PrimaryNavLinks>
            <li>
              <Link
                to="/about"
                style={{
                  color: `white`,
                  textDecoration: `none`,
                }}
              >
                <span>About</span>
              </Link>
            </li>
            <li>
              <Link
                to="/articles"
                style={{
                  color: `white`,
                  textDecoration: `none`,
                }}
              >
                <span>News</span>
              </Link>
            </li>
            <li
              onMouseEnter={() => setShowContestNav(true)}
              onMouseLeave={() => setShowContestNav(false)}
            >
              <Link
                to="/contests"
                style={{
                  color: `white`,
                  textDecoration: `none`,
                }}
              >
                <span>
                  <span className="eating" />Contests
                </span>
              </Link>
              {showContestNav && (
                <SecondaryNav>
                  <li>
                    <Link
                      to="/contests"
                      style={{
                        color: `white`,
                        textDecoration: `none`,
                      }}
                    >
                      Events
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/join"
                      style={{
                        color: `white`,
                        textDecoration: `none`,
                      }}
                    >
                      Participate
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/host"
                      style={{
                        color: `white`,
                        textDecoration: `none`,
                      }}
                    >
                      Host an Event
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/safety"
                      style={{
                        color: `white`,
                        textDecoration: `none`,
                      }}
                    >
                      Safety Standards
                    </Link>
                  </li>
                </SecondaryNav>
              )}
            </li>
            <li>
              <Link
                to="/eaters"
                style={{
                  color: `white`,
                  textDecoration: `none`,
                }}
              >
                <span>
                  <span className="eating">Eater&nbsp;</span>Rankings
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/records"
                style={{
                  color: `white`,
                  textDecoration: `none`,
                }}
              >
                <span>Records</span>
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                style={{
                  color: `white`,
                  textDecoration: `none`,
                }}
              >
                <span>Contact</span>
              </Link>
            </li>
            <li>
              <a
                href="https://shop.spreadshirt.com/majorleagueeating/"
                target="_blank"
                style={{
                  color: `white`,
                  textDecoration: `none`,
                  borderRight: "5px solid white",
                }}
              >
                <span>Shop</span>
              </a>
            </li>
            <li>
              <a className="lastLink" />
            </li>
          </PrimaryNavLinks>
        </Nav>
      </FluidContainer>
    </HeaderContainer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

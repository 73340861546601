import React from "react"
import { slide as Menu } from "react-burger-menu"
import styled from "@emotion/styled"
import logoSmall from "../images/logo_small.png"
import { Link } from "gatsby"

const MobileNavContainer = styled.div`
  width: 100%;
  background-color: rgb(10, 23, 33);
  position: fixed;
  height: 70px;
  z-index: 1000;
  @media (min-width: 768px) {
    display: none;
  }
`

const Submenu = styled.div`
  margin-top: 0rem !important;
  margin-left: 2rem !important;
  a {
    color: white;
    margin: 1rem 0;
    display: block;
    font-size: 24px;
    text-decoration: none;
  }
`

const LogoContainer = styled.div`
  margin: 0 auto;
  width: 150px;
  position: relative;
  top: 5px;
  height: 0px;
}
`

var menuStyles = {
  bmBurgerButton: {
    width: "36px",
    height: "30px",
    left: "36px",
    top: "21px",
    position: "fixed",
  },
  bmBurgerBars: {
    background: "white",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: "#373a47",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmItem: {
    display: "block",
    fontFamily: "oswald",
    textTransform: "uppercase",
    fontSize: "2rem",
    color: "white",
    textDecoration: "none",
    marginBottom: "2rem",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
}

class MobileNav extends React.Component {
  render() {
    return (
      <MobileNavContainer>
        <LogoContainer>
          <Link to="/">
            <img src={logoSmall} />
          </Link>
        </LogoContainer>
        <Menu styles={menuStyles}>
          <Link id="home" className="menu-item" to="/">
            Home
          </Link>
          <Link id="events" className="menu-item" to="/contests">
            Contests
          </Link>
          <Submenu>
            <Link id="events-smal" className="menu-item" to="/contests">
              Events
            </Link>
            <Link id="host" className="menu-item small" to="/host">
              Host
            </Link>
            <Link id="safety" className="menu-item small" to="/safety">
              Safety
            </Link>
          </Submenu>
          <Link id="rankings" className="menu-item" to="/eaters">
            Rankings
          </Link>
          <Link id="records" className="menu-item" to="/records">
            Records
          </Link>
          <Link id="news" className="menu-item" to="/articles">
            News
          </Link>
          <Link id="about" className="menu-item" to="/about">
            About
          </Link>
          <Link id="contact" className="menu-item" to="/contact">
            Contact
          </Link>
          <a
            id="nft"
            href="https://nft.majorleagueeating.com"
            target="_blank"
            className="menu-item"
          >
            <span>NFT</span>
          </a>
          <a
            id="shop"
            className="menu-item"
            target="_blank"
            href="https://shop.spreadshirt.com/majorleagueeating/"
          >
            Shop
          </a>
        </Menu>
      </MobileNavContainer>
    )
  }
}

export default MobileNav

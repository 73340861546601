import React from "react"
import styled from "@emotion/styled"

export default styled.div`
  position: relative;
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 2rem;
  @media (max-width: 768px) {
    padding: 0rem 1rem;
  }

  @media (max-width: 576px) {
    padding: 0rem 0.5rem;
  }
`
